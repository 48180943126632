import statsigInstance from "@/utils/statsig"
import { TS_SDK_BIZPARAMS } from "~/composables/utils/tsSdkPoint/newFeature"
enum Experiment {
  BODY_EDITOR_HERO = "bodyeditor_showherovideo",
  HOME_SEE_FEATURE = "home_seeallfeature"
}
export default function useStatsig() {
  const showAllFeature = async (): Promise<boolean> => {
    const statsig = await statsigInstance.getStatsigClient()
    return (statsig.getExperiment(Experiment.HOME_SEE_FEATURE).value["showAllFeature"] ?? false) as boolean
  }
  const showBodyEditorVideo = async (): Promise<boolean> => {
    const statsig = await statsigInstance.getStatsigClient()
    return (statsig.getExperiment(Experiment.BODY_EDITOR_HERO).value["showVideo"] ?? false) as boolean
  }
  const setStatsigFlag = (params = {}) => {
    // 埋点上报添加AB标识参数
    const _params = {
      action_json: { ...params }
    }
    const biz_params = JSON.parse(getCookie(TS_SDK_BIZPARAMS) || "{}")
    setCookie(
      TS_SDK_BIZPARAMS,
      JSON.stringify({
        ...biz_params,
        ..._params
      })
    )
  }
  const clearStatsigFlag = () => {
    clearCookie(TS_SDK_BIZPARAMS)
  }
  return {
    setStatsigFlag,
    clearStatsigFlag,
    showAllFeature,
    showBodyEditorVideo
  }
}
