import { StatsigClient, StatsigOptions, StatsigUser } from "@statsig/js-client"
const SDK_KEY = "client-sHFs23BGlZS2eMfZvnc4nZXpRycUP4ZzforwjitcH5K"
const options: StatsigOptions = {
  plugins: []
}
const useStatsigUser = () => useState("statsig_user_id", () => "")
const generateRandomId = () => {
  // 获取当前时间戳（毫秒）
  const timestamp = Date.now()
  // 生成一个随机数，确保唯一性
  const randomNum = Math.floor(Math.random() * 1000000)
  // 组合时间戳和随机数作为 ID
  const randomId = `${timestamp}-${randomNum}`

  return randomId
}

const initUser = (): StatsigUser => {
  let userID
  const statsig_user = useStatsigUser()
  const statsig_user_cookie = useCookie("statsig_user_id", { path: "/" })
  console.log("----initUser: ", "state: " + statsig_user.value, "cookies: " + statsig_user_cookie.value)
  if (!statsig_user.value && !statsig_user_cookie.value) {
    userID = generateRandomId()
    statsig_user.value = userID
  } else {
    userID = statsig_user_cookie.value || statsig_user.value
  }
  if (process.client) {
    if (!statsig_user_cookie.value) {
      setCookie("statsig_user_id", statsig_user.value, 30, { path: "/" })
    }
  }
  return {
    userID,
    custom: {
      environment: process.env.NUXT_APP_ENV
    }
  }
}

class Statsig {
  private static instance: Statsig | null = null // 存储唯一实例
  private myStatsigClient: StatsigClient // 存储 StatsigClient 实例

  // 私有构造函数，确保外部无法直接实例化
  private constructor() {}

  // 获取实例的静态方法
  public static getInstance(): Statsig {
    if (!Statsig.instance) {
      Statsig.instance = new Statsig()
    }
    return Statsig.instance
  }
  public async init() {
    const user = initUser()
    const myStatsigClient = new StatsigClient(SDK_KEY, user, options)
    await myStatsigClient.initializeAsync()
    this.myStatsigClient = myStatsigClient
  }
  public getExperiment(experimentName: string) {
    if (!this.myStatsigClient) {
      throw new Error("Statsig client is not initialized")
    }
    return this.myStatsigClient.getExperiment(experimentName)
  }
  // 获取 StatsigClient 实例
  public async getStatsigClient() {
    if (!this.myStatsigClient) {
      await this.init()
    }
    return this.myStatsigClient
  }
}
export default Statsig.getInstance()
